// #region Resets
* {
  /* General resets */
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}
// #endregion
// #region react-toastify overrides
.Toastify__toast {
  border-radius: 4px;
  min-height: 50px;
}
// If you want to move it down some:
// .Toastify__toast-container--top-left {
//   top: 25vh;
// }
// #endregion
// #region react-tabs overrides
// See https://github.com/reactjs/react-tabs
.react-tabs__tab {
  /* Prevent selecting text when clicking react-tabs. */
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  /* Custom styling for non-selected tabs. */
  background: #256394;
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  margin-bottom: 1px;
  opacity: 0.6;
}
.react-tabs__tab--selected {
  /* Custom styling for selected tabs. */
  background: #ffffff;
  color: #667a8a;
  opacity: 1;
}
// #endregion
// #region Elements by Tag
body {
  padding: 0;
  border: 0;
  margin: 0;
  font-family: "Assistant", arial;
  font-size: 13.25px;
  line-height: 17px;
  color: #666;
  max-width: 100%;
}
button,
input {
  font-family: "Assistant", arial;
}
button {
  padding: 7px 15px;
  text-align: center;
  background: #00abc7;
  color: #fff;
  border-radius: 2px;
  border: 1px solid #2dadc2;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.37) inset,
    1px 0 1px rgba(255, 255, 255, 0.07) inset, 0 1px 0 rgba(0, 0, 0, 0.36),
    0 -2px 12px rgba(0, 0, 0, 0.08) inset;
  cursor: pointer;
}
button:active {
  background: #386f80;
  outline: none;
  border-color: #006e90;
  box-shadow: none;
}
.button-mui {
  font-weight: bolder !important;
  background: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  border-color: #3f51b5 !important;
}
.button-mui:active {
  background: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  border-color: #3f51b5 !important;
}
// #endregion
// #region Elements by Id
#welcome {
  padding: 10px 0;
  text-align: right;
  .row-2 {
    margin-top: 20px;
    user-select: none;
    input {
      margin-left: 10px;
    }
  }
  .row-3 {
    margin-top: 20px;
    user-select: none;
    text-align: left;
  }
}
#refresh_filelist {
  background: url("../images/retry.gif") right center no-repeat;
  padding-right: 20px;
  margin-right: 0;
  border-right: none;
}
#refresh_filelist:hover {
  opacity: 1;
  text-decoration: underline;
  color: #666;
}
#filelist {
  max-height: 389px;
  overflow: auto;
}
input#join_newname {
  border: none;
  border-bottom: 1px dotted #ccc;
  outline: none;
  line-height: 18px;
  width: 250px;
  padding: 5px;
}
// #endregion
// #region Classes
.action_links {
  float: right;
  font-size: 13.25px;
  color: #333;
  opacity: 0.6;
  cursor: pointer;
  border-right: 1px solid #ccc;
  padding: 0 7px;
}
.action_links:hover {
  opacity: 1;
  text-decoration: underline;
  color: #666;
}
.action_links:first-child {
  border: 0px;
}
.active_tab {
  float: left;
  padding: 10px 15px 5px;
  background: #fff;
  color: #667a8a;
  border-radius: 3px 3px 0 0;
  display: flex;
  line-height: 23px;
}
.active_tab > i {
  margin-right: 5px;
}
.btn_preview {
  margin-right: 2px;
  width: 150px;
}
.resume_upload {
  background: rgb(113, 37, 201);
  border: 1px solid rgb(141, 67, 226);
  position: absolute;
  right: 95px;
  top: 6px;
  white-space: nowrap;
}
.cancel_upload {
  position: absolute;
  right: 10px;
  top: 6px;
  white-space: nowrap;
}
.clear {
  clear: both;
}
.cns_block {
  /*padding: 10px;*/
  font-size: 13.25px;
  font-family: arial;
  background: #000;
  color: #6b86a0;
  line-height: 1.3;
}
.cns_btn {
  padding: 7px 9px;
  display: inline-block;
  border-right: 1px solid #000;
  cursor: pointer;
  border-left: 1px solid #444;
  border-bottom: 1px solid #111;
  border-top: 1px solid #444;
  background: #333;
  text-shadow: -1px -1px 0px #111;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
}
.cns_btn:hover {
  color: #72b9ff;
}
.cns_btn:active {
  background: #16293c;
  border-color: #111;
  text-shadow: 1px 1px 1px #000;
}
.cns_btn_main {
  color: #a292af;
}
.cns_btn_main:hover {
  color: #d1b1ea;
}
.cns_controls {
  clear: both;
  text-align: center;
  padding: 0 0 10px 0;
  background: #222;
}
.cns_header {
  line-height: 1.3;
  padding: 10px;
  color: #ccc;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.cns_header_title {
  display: flex;
  align-items: center;
}
.cns_input {
  background: none;
  border: 0;
  padding: 2px;
  margin: 0;
  font-size: 13.25px;
  font-family: arial;
  width: auto;
  line-height: 25px;
  color: #fff;
}
.cns_tools_block {
  /*display: inline-block;*/
  display: flex;
  padding: 0 6px;
}
.cns_tools_block > * {
  flex: auto;
}
.confirm_del {
  display: inline-block;
  padding: 5px 10px;
  background: #a33;
  border-radius: 3px;
  position: absolute;
  // left: 38px;
  right: 38px;
  top: 8px;
  color: #fff;
}
.dropzone {
  min-height: 200px;
  max-height: 490px;
  overflow-y: hidden;
  width: inherit;
  border-radius: 6px;
  background-color: #fdfdfd;
  border: 1px dashed #cccccc;
  padding: 20px;
}
.dropzone-button {
  display: inline;
  cursor: pointer;
  padding: 7px 15px;
  text-align: center;
  float: left;
  background: #00abc7;
  color: #ffffff;
  border-radius: 2px;
  border: 1px solid #2dadc2;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.37) inset,
    1px 0 1px rgba(255, 255, 255, 0.07) inset, 0 1px 0 rgba(0, 0, 0, 0.36),
    0 -2px 12px rgba(0, 0, 0, 0.08) inset;
  div {
    text-align: center;
    color: #ffffff;
  }
}
.dropzone-text {
  position: absolute;
  font-size: 200%;
  left: 0;
  width: 100%;
  text-align: center;
  top: 45%;
  opacity: 0.25;
  line-height: 1.2;
}
.file_item {
  border-top: 1px solid #ddd;
  padding: 10px 45px 10px 10px;
  cursor: pointer;
  position: relative;
  transition: 0.1s;
  word-break: break-all;
  display: flex;
  align-items: center;
  /* NOTE: The user wants to be able to copy the filename. Removing the
  following user-select rules was the simplest way to do this quickly. However,
  TODO: Find a better way to let the user copy the filename without manually
  selecting text (such as a button or menu item that they can click). */
  // user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // -webkit-user-select:none;

  .error {
    color: #a33;
    font-style: italic;
    font-weight: bold;
    margin-left: 4px;
  }
}
.file_item:hover {
  background: #f3f9ff;
  color: #333;
}
.file_item.buttons_visible {
  padding-right: 108px;
  transition: 0.1s;
}
.file_item.new {
  background: #ffddb3;
}
.file_item.active {
  background: #0091bd;
  color: #fff;
  /*box-shadow: 0 1px 1px rgba(255, 255, 255, 0.37) inset, 1px 0 1px rgba(255, 255, 255, 0.07) inset, 0 1px 0 rgba(0, 0, 0, 0.36), 0 -2px 12px rgba(0, 0, 0, 0.08) inset;*/
}
.file_item.error {
  background: rgb(255, 226, 234);
}
.file_item.processed {
  background: #dfe8dd;
  cursor: default;
}
.file_item.sortable {
  background-image: url("../images/order_arrows.svg");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}
.file_item > i {
  padding-right: 5px;
}
.filelist_header {
  color: #7c9aab;
  padding: 10px;
}
.filename {
  flex: auto;
}
.filesize {
  font-size: 10px;
  color: #aaa;
  padding: 0 5px;
  width: 64px;
  flex: auto;
  text-align: right;
}
.filesize .active {
  color: #ddd;
}
.heading {
  font-size: 18.25px;
  padding: 10px 0;
}
.hide {
  display: none !important;
}
.icon_del {
  background: #fff url("../images/trash.gif") no-repeat center center;
  width: 15px;
  height: 15px;
  vertical-align: sub;
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
  position: absolute;
  // left: 10px;
  right: 10px;
  top: 10px;
}
.icon_del.cancel {
  background: #fff url("../images/cancel.png") no-repeat center center;
}
.icon_processing {
  background: #fff url("../images/loading.gif") no-repeat center center;
  width: 15px;
  height: 15px;
  vertical-align: sub;
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
  position: absolute;
  // left: 10px;
  right: 10px;
  top: 10px;
}
.icon_processing.icon_done {
  background: url("../images/tick.png") no-repeat center center;
}
.input_block {
  padding: 10px;
  input {
    line-height: 18px;
    padding: 5px;
  }
}
.ispeed {
  padding: 3px 10px;
  display: inline-block;
  margin-right: 3px;
  border-radius: 3px;
  background: #eee;
  cursor: pointer;
}
.ispeed:hover {
  background: #dae1e2;
  color: #333;
}
.ispeed.active {
  color: #fff;
  background: #00abc7;
}
.loading_spinner {
  background: url("../images/balls_line.gif") no-repeat center bottom;
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 150px;
}
.preview_block {
  line-height: 0;
  background: #5f5f5f;
  text-align: center;
}
.tool_header {
  padding: 5px 5px 0;
  border-radius: 4px 4px 0 0;
  background: #224277;
  color: #fff;
  font-size: 14px;
}
.tool_header .action_links {
  padding: 5px 10px;
  display: inline-block;
  border-radius: 3px 3px 0 0;
  background: #256394;
  color: #fff;
  font-size: 15px;
  border: none;
  margin-top: 10px;
  margin-left: 2px;
  display: flex;
  line-height: 19px;
  transition: 0.1s;
}
.tool_header .action_links > i {
  font-size: 19px;
  margin-right: 7px;
}
.tool_header .action_links:hover {
  text-decoration: none;
  padding-bottom: 12px;
  margin-top: 3px;
}
.tool_header .action_links:active {
  background: #082048;
}
// #endregion
// #region Developer
.margin-top-12 {
  margin-top: 12px;
}

.files-toolbar {
  padding-left: 250px;
}

.files-list {
  list-style: none;
  li {
    cursor: default;
    padding: 4px;
    user-select: none;
  }
}

.files-speed {
  padding-left: 220px;
}

.player-container {
  min-width: 480px;
  min-height: 270px;
  background-color: lightgray;
}
// #endregion
// #region Uploader
.admin_toolbar {
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 5px;
  border: 1px solid #cccccc;
  border-radius: 0 0 4px 4px;
  background-color: #eee;
}
// #endregion
// #region Transfer File Modal
.modal_header {
  top: 0px;
  left: 0px;
  right: 10px;
  position: sticky;
  padding: 20px;
  height: 95px;
  border-bottom: 1px dashed #cccccc;
  background-color: #eee;
  h3 {
    float: left;
    margin-top: 10px;
  }
  .modal_header_buttons {
    float: right;
  }
  .modal_header_body {
    margin-top: 40px;
  }
}
.modal_body {
  padding: 20px;
}
.user_list {
  list-style: none;
  li {
    cursor: pointer;
    padding: 4px;
    user-select: none;
    border-bottom: 1px solid #cccccc;
  }
  li.active {
    background: #0091bd;
    color: #fff;
  }
}
// #endregion
